import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Blog from "./Blog";
import MentorCard from "./MentorCard";
import Form2 from "./Form2";
import CompleteBlog from "./CompleteBlog";
import CVReview from "./CVReview";
import MockInterview from "./MockInterview";
import CareerGuidance from "./CareerGuidance";
import Content from "./Content";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import ContactPage from "./ContactPage";
import ScrollToTop from "./ScrollToTop"; // Import ScrollToTop
import PaymentConfirmation from "./PaymentConfirmation";
import { PaymentProvider } from "./PaymentContext";
import { DataProvider } from "./DataProvider";
import RegistrationForm2 from "./RegistrationForm2";
// import { DataScienceInterviewGuide } from "./DataScienceInterviewGuide";

// Layout Component to wrap routes
const Layout = () => (
  <>
    <DataProvider>
      <ScrollToTop />
      <PaymentProvider>
        <Outlet />
        {/* Renders the matched child route */}
      </PaymentProvider>
    </DataProvider>
  </>
);

const Body = () => {
  const appRouter = createBrowserRouter([
    {
      element: <Layout />, // Wrap all routes with Layout
      children: [
        { path: "/", element: <Home /> },
        { path: "/about", element: <About /> },
        { path: "/contact", element: <ContactPage /> },
        { path: "/blogs", element: <Blog /> },
        { path: "/content", element: <Content /> },
        { path: "/form2/:id", element: <Form2 /> },
        { path: "/completeblog/:title", element: <CompleteBlog /> },

        { path: "/mentor/:id", element: <MentorCard /> },
        { path: "/registrationform2", element: <RegistrationForm2 /> },

        { path: "/cvreview", element: <CVReview /> },
        { path: "/careerguidance", element: <CareerGuidance /> },
        { path: "/mockinterview", element: <MockInterview /> },
        { path: "/privacy-refund-policy", element: <PrivacyPolicy /> },
        { path: "/termsandconditions", element: <TermsAndConditions /> },
        { path: "/payment-success", element: <PaymentConfirmation /> },
        // { path: "/datascienceinterviewguidance", element: <DataScienceInterviewGuide /> },
      ],
    },
  ]);

  return <RouterProvider router={appRouter} />;
};

export default Body;
