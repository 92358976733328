import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import contactUs_img from "../assets/contact_us.png";
import { FaFacebook, FaInstagram, FaYoutube, FaLinkedin, FaWhatsapp } from "react-icons/fa";

const ContactPage = () => {
  const infoItems = [
    {
      icon: "📞",
      title: "Contact Number",
      description:
        "Our team is ready to assist you with any inquiries. Reach us by phone at:",
      highlight: "+91 7756043707",
      link: "#",
    },
    {
      icon: "💬",
      title: "Email Us",
      description: "For questions, support, or more information, email us at:",
      highlight: "info@inttrvu.ai",
      link: "mailto:info@inttrvu.ai",
    },
    {
      icon: "📍",
      title: "Office Address",
      description:
        "Office No. 307 , Acharya House, Plot No.24 Sr.No.19/12/1, Bavdhan, Pune, Maharashtra-411021",
      highlight: "Get Location",
      link: "https://maps.app.goo.gl/n5LQUiKSoDe9uw9W8",
    },
  ];

  return (
    <div className="bg-gray-50">
      <Header />

      {/* Hero Section */}
      <div className="relative h-[20rem] md:h-[30rem]">
        <img
          className="w-full h-full object-cover"
          src={contactUs_img}
          alt="Background"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="absolute inset-0 flex items-center px-6 md:px-16">
          <div className="text-white max-w-2xl">
            <h2 className="text-3xl md:text-5xl font-bold mb-4">Contact Us</h2>
            <p className="text-lg md:text-xl">
              We’re here to help! If you have any questions, comments or need
              assistance, please don’t hesitate to get in touch with us.
            </p>
          </div>
        </div>
      </div>

      {/* Contact Info Section */}
      <div className="container mx-auto px-4 py-12 bg-gray-50">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {infoItems.map((item, index) => (
            <div
              key={index}
              className="bg-white border border-black p-6 rounded-lg shadow hover:shadow-md transition-shadow"
            >
              <div className="text-4xl text-blue-500 mb-4">{item.icon}</div>
              <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
              <p className="text-gray-600 mb-4">{item.description}</p>
              <a
                href={item.link}
                className="text-blue-500 font-medium hover:underline flex items-center"
              >
                {item.highlight} <span className="ml-1">→</span>
              </a>
            </div>
          ))}
        </div>
      </div>

      {/* Connect with Us Section */}
      <div className="bg-black text-white py-12 px-6 md:px-16">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div>
            <h2 className="text-3xl font-semibold text-center md:text-left">
              Connect with Us
            </h2>
            <p className="mt-4 text-lg text-center md:text-left">
              Follow us on our social media platforms for the latest updates.
            </p>
          </div>
          <div className="flex space-x-6 mt-6 md:mt-0">
            {[
              {
                icon: <FaFacebook />,
                link: "https://www.facebook.com/people/inttrvuai/100092270186906/?mibextid=LQQJ4d",
              },
              {
                icon: <FaInstagram />,
                link: "https://www.instagram.com/inttrvu.ai/?igshid=NTc4MTIwNjQ2YQ",
              },
              {
                icon: <FaYoutube />,
                link: "https://www.youtube.com/@INTTRVUai",
              },
              {
                icon: <FaLinkedin />,
                link: "https://www.linkedin.com/in/rohit-mande-15a3a154/",
              },
              {
                icon: <FaWhatsapp />,
                link: "https://web.whatsapp.com/",
              },
            ].map(({ icon, link }, index) => (
              <a
                key={index}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-4xl hover:text-yellow-300 transition-transform transform hover:scale-125"
              >
                {icon}
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* Map Section */}
      <div className="container mx-auto px-4 py-12">
        <div className="rounded-lg overflow-hidden shadow-md">
          <iframe
            title="location-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.341056110335!2d73.77751282472244!3d18.513483747736153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf1b9a03ac2d%3A0x566748ed1ac6dbd7!2sAcharya%20House!5e0!3m2!1sen!2sin!4v1732789913403!5m2!1sen!2sin"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div> 
      </div>

      <Footer />
    </div>
  );
};

export default ContactPage;
