import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const DynamicCard = () => {
  // const [data, setData] = useState([]);
  const navigate = useNavigate();

//  useEffect(() => {
//   axios
//   .get("/db.json")
//   .then((response) => {
//     setData(response.data.mentors); // Assuming the JSON structure has a "mentors" array
//   })
//   .catch((error) => console.error("Error fetching mentors:", error));
// }, []);

const [mentors, setMentors] = useState([]);
 useEffect(() => {
  // Fetch data from your backend
  const fetchMentors = async () => {
    try {
      const response = await axios.get("https://prodbackend.inttrvuprep.com/saveAvailability");
      setMentors(response.data);
      
    } catch (err) {
      console.error(err.message);
      
    }
  };

  fetchMentors();
}, []);
  const handleShowForm = (id,fees) => {
    navigate(`/form2/${id}`, { state: { flag: 3 , fees} }); 
  };

  return (
    <div className="bg-black text-white pt-14 pb-14">
      {/* Header Section */}
      <div className="text-center">
        <h2 className="text-2xl md:text-3xl font-semibold">Select Your Mentor</h2>
        <div className="flex justify-center mt-2">
          <div className="h-1 w-32 bg-cyan-500"></div>
        </div>
      </div>
     

      {/* Cards Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4 sm:px-8 md:px-16 mt-10">
       {mentors.map((d, i) => (
          <div
            key={i}
            className="flex flex-col bg-gray-800 text-white p-4 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300"
          >
            {/* Top Section: Photo + Details */}
            <div className="flex flex-col sm:flex-row sm:items-center">
              {/* Profile Photo */}
              <div className="flex-shrink-0 mb-4 sm:mb-0 sm:mr-4">
                <img
                  className="rounded-lg shadow-lg w-32 h-32 object-cover"
                  src={d.img}
                  alt="profile"
                />
              </div>

              {/* Details Section */}
              <div className="flex flex-col justify-between sm:ml-4">
                <div>
              
                  <h2 className="text-lg sm:text-xl font-bold">{d.name}</h2>
                  <p className="text-sm ">{d.position}</p>
                  <p className="text-sm ">{d.experience}</p>

                  {/* About */}
                  <p className="mt-3 text-sm ">{d.about}</p>
                </div>
              </div>
            </div>

            {/* Bottom Section: Technologies, Price, and Button */}
            <div className="mt-4 pt-4">
              {/* Technologies */}
              <div className="flex flex-wrap gap-2">
                {d.technologies.map((tech, index) => (
                  <span
                    className="px-4 py-1 text-xs sm:text-sm bg-gray-100 text-black rounded-full"
                    key={index}
                  >
                    {tech}
                  </span>
                ))}
              </div>

              {/* Price and Button */}
              <div className="mt-4 flex justify-between items-center">
                <div className="text-lg font-bold text-cyan-400">
                  &#8377; {d.price} / 45 mins Session
                </div>
                <button
                  className="px-6 py-2 bg-blue-700 text-white font-semibold rounded-lg hover:bg-blue-600 transition-all duration-300"
                  onClick={() => handleShowForm(d.id , d.price)}
                >
                  Schedule a Mock
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DynamicCard;
