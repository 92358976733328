import React, { useState, useEffect,useRef } from "react";
import ScheduledItem from "./ScheduledItems";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useData } from "./DataProvider";


const RegistrationForm2 = ({ details }) => {
  const { mentorName } = useData();

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNo: "",
    verification: "",
    technology:"",
  });

  const [otpError, setOtpError] = useState(false);

  const [otpSent, setOtpSent] = useState(false);

  const [contactError, setContactError] = useState("");
  const [mailError, setMailError] = useState("");

  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});



   const maxSelection = 3;
      const [selectedTechnologies, setSelectedTechnologies] = useState([]);
      const [isOpen, setIsOpen] = useState(false);
      const dropdownRef = useRef(null);
  
      const toggleDropdown = () => {
          setIsOpen(!isOpen);
      };
  
      const availableTechnologies = [
          "Data Science",
          "Python",
          "HR and behavioral",
          "Data Analyst",
          "Tableau",
          "Power BI",
      ];
  
      const handleTechnologySelect = (tech) => {
          if (selectedTechnologies.includes(tech)){
              setSelectedTechnologies(selectedTechnologies.filter((t) => t !== tech));
          } else if (selectedTechnologies.length < maxSelection) {
              setSelectedTechnologies([...selectedTechnologies, tech]);
          }
      };
  
      useEffect(() => {
          const handleClickOutside = (event) => {
              if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                  setIsOpen(false);
              }
          };
  
          document.addEventListener("mousedown", handleClickOutside);
  
          return () => {
              document.removeEventListener("mousedown", handleClickOutside);
          };
      }, []);



  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValidationErrors({
      ...validationErrors,
      [name]: "", // Clear error for the field when user starts typing
    });

    //validation for contact number
    if (name === "contactNo" && !/^\d*$/.test(value)) {
      return;
    }

    setFormData({ ...formData, [name]: value });
    if (name === "email") {
      setMailError("");
      setOtpSent(false); // Clear OTP sent message if email changes
    }
  };

  const validateEmail = () => {
    // Stricter email validation regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(formData.email)) {
      setMailError("Please enter a valid email address.");
      return false;
    }

    // Additional checks for disallowed special characters in the local part
    const localPart = formData.email.split("@")[0];
    if (/[^a-zA-Z0-9._-]/.test(localPart)) {
      setMailError("Email local part contains invalid characters.");
      return false;
    }

    return true;
  };

  // Form submission logic
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default submission behavior

    // Additional validation for contact number
    if (!/^\d{10}$/.test(formData.contactNo)) {
      alert("Contact number must be exactly 10 digits.");
      return;
    }

    setContactError(""); // Clear the error if validation passes

    const completeData = {
      ...formData,
      // (Skill) selectedTechnologies,
      date: details.date,
      timeSlot: details.timeSlot,
    };
    alert("Form Submitted");
    console.log(completeData);
  };

  // Email verification
  //send otp and verify otp
  const handleSendOtp = async () => {
    if (!formData.email) {
      alert("Please enter a valid email address.");
      return;
    }
    if (validateEmail()) {
      try {
        await axios.post("http://51.20.181.217:8000/send-otp", {
          email: formData.email,
        });
        alert("OTP sent successfully to your email.");
        setOtpSent(true);
      } catch (error) {
        console.error("Error sending OTP:", error);
        alert("Failed to send OTP. Please try again.");
      }
    } else {
      alert("Please enter a valid email address before requesting OTP.");
    }
  };

  const handleVerifyOtp = async () => {
    if (!formData.verification) {
      alert("Please enter the OTP.");
      return;
    }

    try {
      await axios.post("http://51.20.181.217:8000/verify-otp", {
        email: formData.email,
        otp: formData.verification,
      });
      alert("Email verified successfully.");
      setOtpError(false); // Clear error on successful verification
      return true;
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setOtpError(true); // Show error message for invalid OTP
      return false;
    }
  };

  const handleConfirmationMail = async () => {
    if (!formData.email) {
      alert("Please enter the mail Id.");
      return;
    }

    try {
      await axios.post("http://51.20.181.217:8000/schedule-event", {
        name: formData.fullName,
        email: formData.email,
        date: details.date,
        time: details.timeSlot,
        mentorName: mentorName,
      });
      alert("Email send successfully.");
    } catch (error) {
      console.error("Error Sending mail:", error);
    }
  };

  // pass amount
  const fetchOrderDetails = async () => {
    const amountOne = details.price;
    console.log(amountOne);
    try {
      const response = await axios.post(
        "http://51.20.181.217:8000/create-order",
        { amount: amountOne }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching order details", error);
    }
  };

  //  Razor pay
  const loadRazorpay = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => initializeRazorpay();
    document.body.appendChild(script);
  };

  const initializeRazorpay = async () => {
    const orderDetails = await fetchOrderDetails();
    if (!orderDetails || !orderDetails.amount) {
      console.error("Order data is missing or invalid");
    }
    const options = {
      key: "rzp_live_e0Z8MoR3S0pzZT", // Replace with your Razorpay Key ID
      amount: orderDetails.amount, // Amount in paise
      currency: orderDetails.currency,
      order_id: orderDetails.id, // Dynamically generated order ID
      name: "inttrrvuprep",
      description: "Transaction Description",
      image: "https://example.com/your_logo", // Optional: Add your logo URL

      handler: function (response) {
        navigate(`/payment-success`, {
          state: {
            name: formData.fullName,
            email: formData.email,
            time: details.timeSlot,
            date: details.date,
            price: details.price,
            mentorName: details.mentorName,
            flag: details.flag,
          },
        });
        handleConfirmationMail();
        // alert(`Payment ID: ${response.razorpay_payment_id}`);
        // alert(`Order ID: ${response.razorpay_order_id}`);
        // alert(`Signature: ${response.razorpay_signature}`);
      },

      prefill: {
        name: formData.fullName || "", // Dynamically set from formData
        email: formData.email || "", // Dynamically set from formData
        contact: formData.contactNo || "", // Dynamically set from formData
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.on("payment.failed", function (response) {
      alert(`Error Code: ${response.error.code}`);
      alert(`Description: ${response.error.description}`);
      alert(`Source: ${response.error.source}`);
      alert(`Step: ${response.error.step}`);
      alert(`Reason: ${response.error.reason}`);
      alert(`Order ID: ${response.error.metadata.order_id}`);
      alert(`Payment ID: ${response.error.metadata.payment_id}`);
    });
    rzp.open();
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.fullName) {
      errors.fullName = "Full Name is a required field.";
    }
    if (!formData.email) {
      errors.email = "Email is a required field.";
    } else if (!validateEmail()) {
      errors.email = "Please enter a valid email address.";
    }
    if (!formData.contactNo) {
      errors.contactNo = "Contact Number is a required field.";
    } else if (!/^\d{10}$/.test(formData.contactNo)) {
      errors.contactNo = "Contact number must be exactly 10 digits.";
    }
    if (!formData.verification) {
      errors.verification = "Verification OTP is a required field.";
    }
    if (selectedTechnologies.length === 0) {
      errors.skills = "Required: At least one skill must be selected.";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handlePayment = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      alert("Form submitted successfully!");
    }
    const isOTPVerified = await handleVerifyOtp();

    if (!isOTPVerified) {
      return;
    }
    loadRazorpay();
  };

  return (
    <div className="max-w-lg mx-auto p-6 w-full bg-white">
      {/* Render ScheduleItem component*/}
      <ScheduledItem
        date={details.date}
        timeSlot={details.timeSlot}
        price={details.price}
      />

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Other form fields */}
        <div>
          <label className="block text-m font-semibold text-black">
            Full Name<span className="text-red-500"> *</span>
          </label>
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-400 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          />

          {/* Error message */}
          {!/^[a-zA-Z\s]+$/.test(formData.fullName) &&
            formData.fullName !== "" && (
              <p className="text-red-500 text-sm">
                Full name must contain only letters and spaces.
              </p>
            )}
          {validationErrors.fullName && (
            <p className="text-red-500 text-sm">{validationErrors.fullName}</p>
          )}
        </div>

        <div className="relative">
          <label className="block text-m font-semibold text-black">
            E-Mail Id<span className="text-red-500"> *</span>
          </label>
          <div className="mt-1">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              onBlur={validateEmail}
              className="block w-full p-2 border border-gray-400 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required
            />
            {validationErrors.email && (
              <p className="text-red-500 text-sm">{validationErrors.email}</p>
            )}
          </div>

          <div className="flex items-center mt-2">
            <button
              type="button"
              className="px-5 py-2 bg-blue-600 text-white text-sm font-semibold rounded-md hover:bg-blue-800"
              onClick={handleSendOtp}
            >
              Get OTP
            </button>
            {otpSent && (
              <span className="ml-2 text-green-500">✔️ OTP Sent</span>
            )}
          </div>
        </div>

        {/* otp verification on email */}
        <div>
          <label className="block text-m font-semibold text-black">
            Verification (on email ID)<span className="text-red-500"> *</span>
          </label>
          <input
            type="text"
            name="verification"
            placeholder="Enter OTP"
            value={formData.verification}
            onChange={handleInputChange}
            className={`mt-1 block w-full p-2 border rounded-md shadow-sm ${
              otpError
                ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                : "border-gray-400 focus:ring-blue-500 focus:border-blue-500"
            }`}
            required
          />
          {validationErrors.verification && (
            <p className="text-red-500 text-sm">
              {validationErrors.verification}
            </p>
          )}
          {otpError && (
            <p className="mt-1 text-sm text-red-500">
              Invalid OTP. Please try again.
            </p>
          )}
        </div>

        {/*  Contact */}
        <div>
          <label className="block text-m font-semibold text-black">
            Contact No<span className="text-red-500"> *</span>
          </label>
          <input
            type="tel"
            name="contactNo"
            placeholder="Enter Contact No"
            value={formData.contactNo}
            onChange={handleInputChange}
            pattern="\d{10}" // HTML pattern for exactly 10 digits
            className="mt-1 block w-full p-2 border border-gray-400 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          />

          {/* Error Message */}
          {validationErrors.contactNo && (
            <p className="text-red-500 text-sm">{validationErrors.contactNo}</p>
          )}
          {contactError && <p className="text-red-500 text-sm">{contactError}</p>}

        </div>

         {/* Technology Selection */}
        <div className="relative w-full" ref={dropdownRef}>
    
    <label className="block mb-2 font-semibold text-black">
        Skills <span className="text-red-500"> *</span>(Max select 3 Skills)
    </label>
    <div
        className={`w-full px-0 py-2 border border-gray-300 rounded cursor-pointer ${isOpen ? 'border-blue-500' : ''}`}
        onClick={toggleDropdown}
    >
        <div className="flex flex-wrap gap-2">
            {selectedTechnologies.map((tech) => (
                <div
                    key={tech}
                    className="flex items-center bg-blue-100 text-blue-800 px-2 py-1 rounded-md"
                >
                    <span>{tech}</span>
                    <button
                        className="ml-1 text-black"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleTechnologySelect(tech);
                        }}
                    >
                        &times;
                    </button>
                </div>
            ))}

            {selectedTechnologies.length === 0 && (
                <span className="text-gray-500 pl-2"> Select technologies...</span>
            )}
        </div>
    </div>

    {isOpen && (
        <ul
            className="absolute top-5 transform -translate-y-full mt-1 w-full max-w-xs bg-white border border-gray-300 rounded shadow-lg max-h-40 overflow-y-auto z-10"
            onMouseLeave={() => setIsOpen(false)} // Close dropdown when mouse leaves
        >
            {availableTechnologies.map((tech) => (
                <li
                    key={tech}
                    className={`px-3 py-2 flex items-center justify-between hover:bg-gray-100 cursor-pointer ${selectedTechnologies.includes(tech) ? 'bg-blue-100 text-blue-800' : 'text-gray-700'}`}
                    onClick={() => handleTechnologySelect(tech)}
                >
                    <span>{tech}</span>
                    <span className={`ml-2 ${selectedTechnologies.includes(tech) ? "text-green-500" : "text-transparent group-hover:text-black"}`}>
                        ✔
                    </span>
                </li>
            ))}
        </ul>
    )}
</div>

{/* Show error message for skills if validation fails */}
{validationErrors.skills && (
    <div className="text-red-500 text-sm mt-2">{validationErrors.skills}</div>
)}


        {/* Proceed Button */}
        <button
          id="rzp-button1"
          type="button"
          className="w-64 py-3 px-4 bg-blue-600 text-white font-bold rounded-md shadow-sm hover:bg-blue-700"
          onClick={(e) => {
            handlePayment(e);
          }}
        >
          Pay
        </button>


      </form>
    </div>
  );
};

export default RegistrationForm2;
