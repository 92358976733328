import React from "react";
import { useLocation } from "react-router-dom";

const PaymentConfirmation = () => {
  const location = useLocation();
  const { date, time, name, mentorName, email, flag, price } = location.state || {};
//https://api.razorpay.com/v1/orders
// {
//   "amount": 100,
//   "currency": "INR",
//   "receipt": "receipt1"
// }
  const serviceName = () => {
    switch (flag) {
      case 1:
        return "CV Review";
      case 2:
        return "Career Guidance";
      case 3:
        return "Mock Interview";
      default:
        return "xyz";
    }
  };

  return (
    <div className="min-h-screen bg-gray-800 flex items-center justify-center px-4">
      <div className="max-w-sm sm:max-w-md lg:max-w-xl w-full bg-white shadow-lg rounded-lg p-4 sm:p-6 text-center">
        <h1 className="text-xl sm:text-2xl font-bold text-green-500 mb-4 sm:mb-6">Payment Successful!</h1>
        <h2 className="text-lg sm:text-xl font-semibold text-green-500 mb-4">₹ {price}</h2>

        <div className="mt-4 text-left">
          <h2 className="text-md sm:text-lg font-semibold text-black mb-2">Hello {name},</h2>
          <p className="text-sm sm:text-base text-black mt-2">
            Thank you for choosing our {serviceName()} Service from inttrrvuprep.com .
          </p>
        </div>

        <div className="mt-4 text-left">
          <h3 className="text-md sm:text-lg font-semibold text-black mb-2">Details:</h3>
          <p className="text-sm sm:text-base text-black">
            <strong>Date:</strong> {date}
          </p>
          <p className="text-sm sm:text-base text-black">
            <strong>Time:</strong> {time}
          </p>
          <p className="text-sm sm:text-base text-black">
            <strong>Mentor:</strong> {mentorName}
          </p>
          <p className="text-sm sm:text-base text-black mt-4">
            A confirmation email has been sent to <strong>{email}</strong>. If you don't see it, please check your spam folder.
          </p>
        </div>

        <div className="mt-6">
          <p className="text-sm sm:text-base text-black italic">
            "Every step you take today brings you closer to your dreams. You're doing amazing, and we're proud of you!"
          </p>
        </div>

        <button
          className="mt-6 px-4 py-2 bg-green-500 text-white rounded-lg shadow-md hover:bg-green-600 text-sm sm:text-base"
          onClick={() => (window.location.href = "/")}
        >
          Go to Home page
        </button>

        <p className="mt-4 text-sm sm:text-base">
          You'll receive confirmation Email in 60 mins. If not received, contact us at <strong>support@intrrvuprep.com</strong>.
        </p>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
