import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { useData } from "./DataProvider";

const MentorCard = () => {
  const { id } = useParams();
  const location = useLocation();
  const { flag, fees } = location.state || {};
  const [mentor, setMentor] = useState(null);
  const { setMentorName } = useData(); // Access context to set mentor name


  // Absolute path

  // useEffect(() => {
  //   axios
  //     .get("http://localhost:3031/mentors")
  //     .then((res) => {
  //       const mentorsData = res.data.mentors || res.data;
  //       const foundMentor = mentorsData.find((mentor) => String(mentor.id) === String(id));
  //       if (foundMentor) {
  //         setMentor(foundMentor);
  //       } else {
  //         console.error(`Mentor with ID ${id} not found`);
  //       }
  //     })
  //     .catch((err) => console.error("Error fetching data:", err));
  // }, [id]);

   // relative path
//   useEffect(() => {
//     axios
//     .get("/db.json")
//     .then((response) => {
//       const mentorsData = response.data.mentors || response.data;
//       const foundMentor = mentorsData.find((mentor) => String(mentor.id) === String(id));
//       if (foundMentor) {
//         setMentor(foundMentor);
//         setMentorName(foundMentor.name); // Update the mentor name in context
//       } else {
//         console.error(`Mentor with ID ${id} not found in  mentorcard`);
//       }
//   })
//   .catch((err) => console.error("Error fetching data:", err));
// }, [id, setMentorName]);




useEffect(() => {
  console.log("id found",id);
  if (!id) {
    console.error("No ID provided to fetch mentor data");
    return;
  }

  axios
    .get("http://13.48.54.45:8000/saveAvailability")
    .then((response) => {
      
      const mentorsData = response.data;
      if (!mentorsData || mentorsData.length === 0) {
        console.error("No mentors data found in response");
        return;
      }

      // Find the specific mentor by ID
      const foundMentor = mentorsData.find((mentor) => String(mentor.id) === String(id));
      if (foundMentor) {
        setMentor(foundMentor);
        setMentorName(foundMentor.name); // Update the mentor name in context
      } else {
        console.error(`Mentor with ID ${id} not found in mentor data`);
      }
    })
    .catch((err) => {
      console.error("Error fetching mentor data:", err.message || err);
    });
}, [id, setMentorName]); // Ensure dependencies are correct


  let headerText;
  let sessionTime
  switch (flag) {
    case 1:
      headerText = "CV Review";
      sessionTime = "30 mins";
      break;
    case 2:
      headerText = "Career Guidance";
      sessionTime = "30 mins";
      break;
    default:
      headerText = "Mock Interviews";
      sessionTime = "45 mins";
  }

  if (!mentor) return <p className="text-center mt-10">Loading...</p>;

  return (
    <div className="bg-white  p-6 text-gray-800">
      {/* Header */}
      <div className="flex items-center space-x-4">
        <div className="w-12 h-12 bg-gray-300 rounded-full overflow-hidden">
          <img
            className="w-full h-full object-cover"
            src={mentor.img}
            alt={`${mentor.name}'s profile`}
          />
        </div>
        <div>
          <h2 className="text-xl font-semibold">{mentor.name}</h2>
          <p className="text-sm text-gray-500">{mentor.position}</p>
        </div>
      </div>

      {/* Title and Pricing */}
      <div className="mt-6">
        <h1 className="text-2xl font-bold text-gray-900">{headerText}</h1>
        <div className="flex items-center text-gray-600 space-x-4 mt-2">
          <div className="flex items-center">
            <svg
              className="w-5 h-5 mr-1 text-gray-800"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 2a8 8 0 100 16 8 8 0 000-16zM8 10H6V6h2v4zm6 0h-2V6h2v4zm0 6h-2v-2h2v2z" />
            </svg>
            <span>{sessionTime}</span>
          </div>
          <span className="text-black font-medium">₹ {fees}</span>
        </div>
      </div>

      {/* Experience */}
      <p className="mt-4 text-gray-700">{mentor.experience}</p>

      {/* Skills */}
      <div className="mt-4">
        <h3 className="font-semibold text-gray-900">Skills</h3>
        <div className="flex flex-wrap mt-2">
          {mentor.technologies.map((tech, index) => (
            <span
              className="px-3 py-1 m-1 text-sm bg-gray-200 text-gray-800 rounded-full"
              key={index}
            >
              {tech}
            </span>
          ))}
        </div>
      </div>

      {/* About Mentor */}
      <div className="mt-4">
        <h3 className="font-semibold text-gray-900">About</h3>
        <p className="mt-3 text-gray-700 leading-relaxed">{mentor.about}</p>
      </div>
    </div>
  );
};

export default MentorCard;
